import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

import Header from '../common/Header';
import Footer from '../common/Footer';

const LegalLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Box component='main' sx={{ flexGrow: 1 }}>
        <Toolbar />
        <Container>{children}</Container>
      </Box>
      <Footer />
    </>
  );
};

export default LegalLayout;
